import React from "react"
// import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import styles from "../styles/work-resources.module.scss"
import ScrollDownArrow from "../components/scroll-down-arrow/scroll-down-arrow"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons"
import cx from "classnames"

const ResourcesPage = () => (
  <div className={styles.bg}>
    <SEO title="Resources" />
    {/* <Layout> */}
    <div className={styles.spacer}></div>
    <section className={styles.work}>
      {/* Fix position doesn't work inside layout.js with AniLink (Gatsby Page Transitions) */}
      {/* <div className={styles.flexDummy}></div> */}
      <div
        className={styles.intro}
        data-sal="fade-in"
        data-sal-delay="200"
        data-sal-easing="ease"
        data-sal-duration="1000"
      >
        <h1>Resources</h1>
        <p>
          My essentials, a reference guide, the holy grail. Find resources that
          I use to get work done when it comes to design and development, plus
          other goodies.
        </p>
        <ScrollDownArrow url="/resources/#plugins" />
      </div>

      <div className={styles.projects}>
        
      <div
          className={styles.project}
          data-sal="fade-in"
          data-sal-delay="2000"
          data-sal-easing="ease"
          data-sal-duration="1000"
        >
          <div className={cx(styles.projectImage, styles.blogOscar)}></div>
          <div className={styles.projectDescription}>
            <a
              href="https://medium.com/oscar-tech/0-to-1-oscars-design-system-journey-e7ee5f688571"
              className={styles.title}
            >
              <div className={styles.icon}>
                <FontAwesomeIcon icon={faExternalLinkAlt} />
              </div>
              <div className={styles.text}>
                <h4>Medium - 0 to 1: Oscar’s Design System Journey </h4>
                <p>
                  Explore how Oscar’s design system Anatomy came to be, how it has evolved over the years, and how its been formalized into the fully supported product it is today.
                </p>
              </div>
            </a>
            <div className={styles.responsibilities}>
              <ul>
                <li>Design Systems</li>
              </ul>
            </div>
          </div>
        </div>

        <div
          className={styles.project}
          data-sal="fade-in"
          data-sal-delay="200"
          data-sal-easing="ease"
          data-sal-duration="1000"
        >
          <div
            className={cx(
              styles.projectImage,
              styles.sketchPlugins,
              styles.embed
            )}
          >
            <video 
              loop autoPlay muted playsInline
              className={styles.video}
              src="https://res.cloudinary.com/antoniolee/video/upload/f_auto,q_auto/v1593660389/Sketch_plugins_workflow_-_short_z8rfuz.mp4">
            </video>
          </div>
          <div className={styles.projectDescription}>
            <a href="https://www.dropbox.com/sh/ol6vash5fh8w46n/AAD4WA5BdoU04rOS7Rr3-XApa?dl=0" className={styles.title}>
              <div className={styles.icon}>
                <FontAwesomeIcon icon={faExternalLinkAlt} />
              </div>
              <div className={styles.text}>
                <h4>Sketch Plugins and Workflow Tips</h4>
                <p>
                  Don't have the time to research the best plugins and how to
                  use them? Well lucky for you, I've created a sketch file that
                  compiles my essential sketch plugins with workflow tips to
                  quickly get you started.
                </p>
              </div>
            </a>
            <div className={styles.responsibilities}>
              <ul>
                <li>Sketch</li>
                <li>Plugins</li>
                <li>Workflow Tips</li>
              </ul>
            </div>
          </div>
        </div>

        <div
          className={styles.project}
          data-sal="fade-in"
          data-sal-delay="200"
          data-sal-easing="ease"
          data-sal-duration="1000"
        >
          <div
            className={cx(
              styles.projectImage,
              styles.sketchAnima,
              styles.embed
            )}
          >
            <video 
              loop autoPlay muted playsInline
              className={styles.video}
              src="https://res.cloudinary.com/antoniolee/video/upload/f_auto,q_auto/v1593646726/sketchandanima_l087ch.mp4">
            </video>
          </div>
          <div className={styles.projectDescription}>
            <a href="https://www.dropbox.com/sh/kczkqxf4vbgbyca/AABuBqZIJGKqLwbD_lXU9krca?dl=0" className={styles.title}>
              <div className={styles.icon}>
                <FontAwesomeIcon icon={faExternalLinkAlt} />
              </div>
              <div className={styles.text}>
                <h4>Prototyping with Sketch and Anima</h4>
                <p>
                  Want to be able to turn your Sketch files into interactive
                  prototypes? Learn how to supercharge your sketch workflow with
                  one of my favorite plugins for Sketch called Anima. I've
                  created a sketch file that goes over real world use cases and
                  examples for how to use Anima with Sketch.
                </p>
              </div>
            </a>
            <div className={styles.responsibilities}>
              <ul>
                <li>
                  <a
                    href="https://support.animaapp.com/en/articles/2627097-how-to-create-a-high-fidelity-prototype-in-sketch"
                    className={styles.externalLink}
                  >
                    View a tutorial from the creators
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* </Layout> */}
  </div>
)

export default ResourcesPage
