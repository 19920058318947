import React from "react"
import styles from "./scroll-down-arrow.module.scss"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"

const ScrollDownArrow = (props) => (
    <div className={styles.upDownArrow}>
        <AnchorLink to={props.url} title={props.title}>
            {/* <img type="svg" className={styles.upDown} src="https://res.cloudinary.com/antoniolee/image/upload/v1593112271/scroll-down-arrow_osfjp8.svg" alt=""></img> */}
            <FontAwesomeIcon icon={faChevronDown} className={styles.upDown}/>
        </AnchorLink>
    </div>
)

export default ScrollDownArrow